/* NotFound.css */
.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    font-size: 4em;
    margin-bottom: 10px;
  }
  
  p {
    font-size: 1.5em;
    color: #888;
  }
  